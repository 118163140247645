import { publicHolidayService } from '../../../services'
import {
  FETCH_PUBLICHOLIDAYS,
  CREATE_PUBLICHOLIDAY,
  UPDATE_PUBLICHOLIDAY,
  DELETE_PUBLICHOLIDAY
} from '../../action-type'
import {
  SET_PUBLICHOLIDAYS,
  ADD_PUBLICHOLIDAY,
  EDIT_PUBLICHOLIDAY,
  REMOVE_PUBLICHOLIDAY
} from '../../mutation-type'

import Enums from '@/utils/enums'

export default {
  state: {
    publicHolidays: []
  },
  getters: {
    activePublicHolidays: state => {
      if (state.publicHolidays && state.publicHolidays.length) {
        return state.publicHolidays.filter(publicHoliday => publicHoliday.status === Enums.Status.Active.value)
      }
      else {
        return []
      }
    }
  },
  mutations: {
    [SET_PUBLICHOLIDAYS](state, publicHolidays) {
      state.publicHolidays = [...publicHolidays]
    },
    [ADD_PUBLICHOLIDAY](state, publicHoliday) {
      state.publicHolidays.push(Object.assign({}, publicHoliday))
    },
    [EDIT_PUBLICHOLIDAY](state, publicHoliday) {
      let index = null
      for (let i = 0; i < state.publicHolidays.length; i++) {
        if (state.publicHolidays[i].id === publicHoliday.id) {
          index = i
          break
        }
      }
      state.publicHolidays.splice(index, 1, publicHoliday)
    },
    [REMOVE_PUBLICHOLIDAY](state, publicHoliday) {
      let index = null
      for (let i = 0; i < state.publicHolidays.length; i++) {
        if (state.publicHolidays[i].id === publicHoliday.id) {
          index = i
          break
        }
      }
      state.publicHolidays.splice(index, 1)
    }
  },
  actions: {
    async [FETCH_PUBLICHOLIDAYS]({ commit }, year) {
      return new Promise((resolve, reject) => {
        publicHolidayService.getAllPublicHolidays(year).then((result) => {
          commit(SET_PUBLICHOLIDAYS, result.items)
          resolve()
        }).catch((error) => {
          reject(error)
        })
      })
    },
    async [CREATE_PUBLICHOLIDAY]({ commit }, publicHoliday) {
      return new Promise((resolve, reject) => {
        publicHolidayService.createPublicHoliday(publicHoliday).then((result) => {
          publicHoliday.id = result.id
          commit(ADD_PUBLICHOLIDAY, publicHoliday)
          resolve()
        }).catch((error) => {
          reject(error)
        })
      })
    },
    async [UPDATE_PUBLICHOLIDAY]({ commit }, holiday) {
      return new Promise((resolve, reject) => {
        publicHolidayService.updatePublicHoliday(holiday).then((result) => {
          commit(EDIT_PUBLICHOLIDAY, holiday)
          resolve()
        }).catch((error) => {
          reject(error)
        })
      })
    },
    async [DELETE_PUBLICHOLIDAY]({ commit }, holiday) {
      return new Promise((resolve, reject) => {
        publicHolidayService.deleteHoliday(holiday.id).then(() => {
          commit(REMOVE_PUBLICHOLIDAY, holiday)
          resolve()
        }).catch((error) => {
          reject(error)
        })
      })
    }
  }
}
