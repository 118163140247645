<template>
  <header>
    <!-- #region header -->
    <!-- SEARCH -->
    <div id="search" role="search">
      <form id="search-form" action="https://chevron.sharepoint.com/search/Pages/results.aspx" target="_blank" method="get">
        <em class="icon-search" aria-hidden="true"></em>
        <input id="search-text" autocomplete="off" type="text" name="k" spellcheck="false" placeholder="search intranet" aria-label="search the intranet">
        <button id="search-clear" type="reset" title="clear" aria-label="clear search text">clear</button>
        <button id="search-button" type="submit" title="search" aria-label="submit search"><em class="icon-arrow-right"></em></button>
      </form>
    </div>
    <span id="toggle-search" class="icon-search" role="button" aria-label="Toggle search form" aria-pressed="false"><em>onesearch</em></span>

    <!-- TOP MENU TOGGLE -->
    <div id="toggle-menu" class="topmenu" role="button" aria-label="Toggle top menu navigation" aria-pressed="false">
      <span class="icon-menu-hamburger"><em>menu</em></span>
      <span class="icon-remove"></span>
    </div>

    <!-- TOP MENU -->
    <div class="headernav" id="top-menu">
      <div id="nav-container">
        <nav class="mainnav" aria-label="NMA SBU Leave Processing Application" aria-hidden="true">
          <ul>
            <li data-subnav="#about">about</li>
            <li><a href="#section1">section one</a></li>
            <li><a href="#section2">section two</a></li>
            <li><a href="#section3">section three</a></li>
            <li data-subnav="#section4">section four</li>
          </ul>
        </nav>
        <nav class="subnav" data-nav-parent="#about" aria-label="about" aria-hidden="true">
          <ul>
            <li class="previous">previous menu</li>
            <li class="parent-link"><a href="#about">about</a></li>
            <li><a href="#about-1">about page one</a></li>
            <li><a href="#about-2">about page two</a></li>
            <li><a href="#about-3">about page three</a></li>
            <li><a href="#about-4">about page four</a></li>
            <li><a href="#about-5">about page five</a></li>
          </ul>
        </nav>
        <nav class="subnav" data-nav-parent="#section4" aria-label="section four" aria-hidden="true">
          <ul>
            <li class="previous">previous menu</li>
            <li class="parent-link"><a href="#section4">section four</a></li>
            <li><a href="#section4-1">detail page one</a></li>
            <li><a href="#section4-2">detail page two</a></li>
            <li><a href="#section4-3">detail page three</a></li>
          </ul>
        </nav>
      </div>
      <hr class="separator">
      <h3>quick links</h3>
      <ul>
        <li><a href="http://inside.chevron.com">inside home</a></li>
        <li><a href="http://whitepages.chevron.com">people finder</a></li>
        <li><a href="http://chaos.chevron.com">chaos</a></li>
      </ul>
      <hr class="separator">
      <ul>
        <li><a href="https://www.timeanddate.com/worldclock/" target="_blank" rel="noopener">world clock</a></li>
        <li><a href="https://www.wunderground.com/" target="_blank" rel="noopener">weather</a></li>
        <li><a href="https://www.chevron.com/" target="_blank" rel="noopener">chevron.com</a></li>
      </ul>
    </div>

    <!-- HALLMARK & SITE TITLE -->
    <div id="sitename">
      <a id="hallmark" href="/"></a>
      <div><a href="/">NMA eLeave</a></div>
    </div>
    <!-- #endregion header -->
  </header>
</template>

<script>

export default {
  name: 'AppHeader'
}

</script>

<style scoped lang="scss">
</style>
