'use strict'

import BaseService from './baseService'
import { Constants } from '../utils/constants'

const serviceURL = `/api/${Constants.BASE_API_VERSION}/studyLeaveRequests`

export default class StudyLeaveRequestService extends BaseService {
  async getAllStudyLeaveRequestsForEmployee(employeeId) {
    let result = await this.sendRequest('GET', `${serviceURL}/all/${employeeId}`)
    return result.data
  }

  async getLastStudyLeaveForEmployee(employeeId) {
    let result = await this.sendRequest('GET', `${serviceURL}/${employeeId}/lastRequest`)
    return result.data
  }
}
