import axios from 'axios'

import AdalAuthentication from '@/adal-auth'
import { ServerValidationError, ELeaveError } from '@/utils/errors'

export default class BaseService {
  async sendRequest(httpMethod, url, data, additionalHeaders, responseType) {
    const token = await AdalAuthentication.acquireToken(process.env.VUE_APP_AZURE_APP_ID)
    let headers = {
      'content-type': data && data.files ? 'multipart/form-data' : 'application/json',
      'Authorization': `Bearer ${token.idToken}`
    }

    for (const headerKey in additionalHeaders) {
      headers[headerKey] = additionalHeaders[headerKey]
    }

    if (data && data.files) {
      let formData = new FormData()
      for (const file of data.files) {
        formData.append(file.name, file.content)
      }
      delete data.files
      //for (const key in data) {
      //  formData.append(key, JSON.stringify(data[key]))
      //}
      formData.append('data', JSON.stringify(data))
      data = formData
    }

    return new Promise((resolve, reject) => {
      axios.request({
        method: httpMethod,
        url,
        data,
        headers: headers,
        responseType: responseType || 'json'
      }).then((response) => {
        resolve(response)
      }).catch((error) => {
        console.log(error.response.data)
        if (error.response.data) {
          if (error.response.data.ELeaveResultCode) {
            reject(new ELeaveError(error.response.data))
          } else if (error.response.status === 400) {
            reject(new ServerValidationError(error.response.data))
          } else {
            reject(new Error(error.response.data.detail))
          }
        } else {
          reject(new Error(error))
        }
      })
    })

    //return axios.request({
    //  method: httpMethod,
    //  url,
    //  data,
    //  headers: headers,
    //  responseType: responseType || 'json'
    //})
  }
}
