import { userService } from '../../../services'

import Enums from '@/utils/enums'

import {
  GET_CURRENT_USER,
  FETCH_USERS,
  CREATE_USER,
  UPDATE_USER,
  CHANGE_USER_STATUS
} from '../../action-type'
import {
  SET_CURRENT_USER,
  SET_USERS,
  ADD_USER,
  EDIT_USER
} from '../../mutation-type'

export default {
  state: {
    currentUser: {
      id: null,
      employeeId: null,
      employeeNo: null,
      firstName: null,
      lastName: null,
      fullName: null,
      employmentDate: null,
      gender: null,
      workScheduleId: null,
      workScheduleName: null,
      locationId: null,
      location: null,
      employeeTypeId: null,
      employeeTypeName:null,
      //employeeType: null,
      supervisorId: null,
      supervisorEmployeeNo: null,
      supervisorName: null,
      alternateEmployeeId: null,
      alternateSupervisorId: null,
      alternateSupervisorEmployeeNo: null,
      alternateSupervisorName: null,
      departmentId: null,
      divisionId: null,
      sectionId: null,
      groupId: null,
      payrollCountryId: null,
      payrollCountry: null,
      sbuStartDate: null,
      isRegularEmployee: null,
      isExpatriateEmployee: null,
      isResidentExpatriateEmployee: null,
      isTemporalEmployee: null,
      isOfficeBasedEmployee: null,
      isRotationalEmployee: null,
      isFieldEmployee: null,
      isShiftEmployee: null,
      isOnForeignService: null,
      isOverseas: null,
      roleId: null,
      roleName: null,
      roleRights: null,
      isPhysician: false,
      canManageRoles: false,
      canManageUsers: false,
      canManagePublicHolidays: false,
      canRaiseMedicalRequests: false,
      canAdministerBenefits: false,
      canRunMedicalReports: false,
      isExpatriateAdmin: false,
      isAdmin: false,
      doas: []
    },
    users: []
  },
  getters: {
    physicians: state => {
      return state.users.filter(user => user.status === Enums.Status.Active.value && (user.roleRights & Enums.RoleRights.IsPhysician.value) === Enums.RoleRights.IsPhysician.value)
    }
  },
  mutations: {
    [SET_CURRENT_USER](state, user) {
      state.currentUser.id = user.id
      state.currentUser.employeeId = user.employeeInfo.employeeId
      state.currentUser.employeeNo = user.employeeInfo.employeeNo
      state.currentUser.firstName = user.employeeInfo.firstName
      state.currentUser.lastName = user.employeeInfo.lastName
      state.currentUser.fullName = user.employeeInfo.fullName
      state.currentUser.employmentDate = user.employeeInfo.employmentDate
      state.currentUser.sbuStartDate = user.employeeInfo.sbuStartDate
      state.currentUser.gender = user.employeeInfo.gender
      state.currentUser.workScheduleId = user.employeeInfo.workScheduleId
      state.currentUser.workScheduleName = user.employeeInfo.workScheduleName
      state.currentUser.locationId = user.employeeInfo.locationId
      state.currentUser.locationName = user.employeeInfo.locationName
      //state.currentUser.employeeType = user.employeeInfo.employeeType
      state.currentUser.employeeTypeId = user.employeeInfo.employeeTypeId
      state.currentUser.employeeTypeName = user.employeeInfo.employeeTypeName
      state.currentUser.supervisorId = user.employeeInfo.supervisorId
      state.currentUser.supervisorEmployeeNo = user.employeeInfo.supervisorEmployeeNo
      state.currentUser.supervisorName = user.employeeInfo.supervisorName
      state.currentUser.alternateEmployeeId = user.employeeInfo.alternateEmployeeId
      state.currentUser.alternateSupervisorId = user.employeeInfo.alternateSupervisorId
      state.currentUser.alternateSupervisorEmployeeNo = user.employeeInfo.alternateSupervisorEmployeeNo
      state.currentUser.alternateSupervisorName = user.employeeInfo.alternateSupervisorName
      state.currentUser.departmentId = user.employeeInfo.departmentId
      state.currentUser.divisionId = user.employeeInfo.divisionId
      state.currentUser.sectionId = user.employeeInfo.sectionId
      state.currentUser.groupId = user.employeeInfo.groupId
      state.currentUser.payrollCountryId = user.employeeInfo.payrollCountryId
      state.currentUser.payrollCountry = user.employeeInfo.payrollCountry
      state.currentUser.isRegularEmployee = user.employeeInfo.isRegularEmployee
      state.currentUser.isExpatriateEmployee = user.employeeInfo.isExpatriateEmployee
      state.currentUser.isResidentExpatriateEmployee = user.employeeInfo.isResidentExpatriateEmployee
      state.currentUser.isTemporalEmployee = user.employeeInfo.isTemporalEmployee
      state.currentUser.isOfficeBasedEmployee = user.employeeInfo.isRotationalEmployee
      state.currentUser.isRotationalEmployee = user.employeeInfo.isOfficeBasedEmployee
      state.currentUser.isFieldEmployee = user.employeeInfo.isFieldEmployee
      state.currentUser.isShiftEmployee = user.employeeInfo.isShiftEmployee
      state.currentUser.isOnForeignService = user.employeeInfo.isOnForeignService
      state.currentUser.isOverseas = user.employeeInfo.isOverseas
      state.currentUser.roleId = user.roleId
      state.currentUser.roleName = user.roleName
      state.currentUser.roleRights = user.roleRights
      state.currentUser.userRights = user.userRights
      state.currentUser.isPhysician = user.isPhysician
      state.currentUser.canManageRoles = user.canManageRoles
      state.currentUser.canManageUsers = user.canManageUsers
      state.currentUser.canManagePublicHolidays = user.canManagePublicHolidays
      state.currentUser.canRaiseMedicalRequests = user.canRaiseMedicalRequests
      state.currentUser.canRunMedicalReports = user.canRunMedicalReports
      state.currentUser.isExpatriateAdmin = user.isExpatriateAdmin
      state.currentUser.canAdministerBenefits = user.canAdministerBenefits
      state.currentUser.isAdmin = user.isAdmin
      state.currentUser.doas = user.employeeInfo.doAs && user.employeeInfo.doAs.length ? user.employeeInfo.doAs.map(v => v.toLowerCase()) : []
      //state.currentUser.isPhysician = (user.roleRights & Enums.RoleRights.IsPhysician.value) === Enums.RoleRights.IsPhysician.value
      //state.currentUser.canRaiseMedicalRequests = (user.roleRights & Enums.RoleRights.RaiseMedicalRequests.value) === Enums.RoleRights.RaiseMedicalRequests.value
    },
    [SET_USERS](state, users) {
      state.users = [...users]
    },
    [ADD_USER](state, { user, rolesModule }) {
      const roles = rolesModule.roles.filter(r => r.id === user.roleId)
      if (roles && roles.length) {
        user.roleName = roles[0].name
      }
      state.users.push(user)
    },
    [EDIT_USER](state, { user, rolesModule }) {
      let index = null
      for (let i = 0; i < state.users.length; i++) {
        if (state.users[i].id === user.id) {
          index = i
          break
        }
      }
      const roles = rolesModule.roles.filter(r => r.id === user.roleId)
      if (roles && roles.length) {
        user.roleName = roles[0].name
      }
      state.users.splice(index, 1, user)
    }
  },
  actions: {
    [GET_CURRENT_USER]({ commit }) {
      return new Promise((resolve, reject) => {
        userService.getCurrenUser().then((result) => {
          commit(SET_CURRENT_USER, result.data)
          resolve()
        }).catch((error) => {
          reject(error)
        })
      })
    },
    async [FETCH_USERS]({ commit }) {
      return new Promise((resolve, reject) => {
        userService.getAllUsers().then((result) => {
          commit(SET_USERS, result.data.items)
          resolve()
        }).catch((error) => {
          reject(error)
        })
      })
    },
    async [CREATE_USER]({ commit, rootState }, user) {
      return new Promise((resolve, reject) => {
        userService.createUser(user).then((result) => {
          commit(ADD_USER, { user, rolesModule: rootState.roleModule })
          resolve()
        }).catch((error) => {
          reject(error)
        })
      })
    },
    async [UPDATE_USER]({ commit, rootState }, user) {
      return new Promise((resolve, reject) => {
        userService.updateRole(user).then((result) => {
          commit(EDIT_USER, { user, rolesModule: rootState.roleModule })
          resolve()
        }).catch((error) => {
          reject(error)
        })
      })
    }
  }
}
