import Enums from '@/utils/enums'
import { codeTypeService } from '../../../services'
import {
  FETCH_CODETYPES
} from '../../action-type'
import {
  SET_CODETYPES
} from '../../mutation-type'
import { CodeTypes } from '@/utils/constants'

export default {
  state: {
    codeTypes: []
  },
  getters: {
    states: state => {
      const states = state.codeTypes.filter(s => s.id === CodeTypes.STATES)
      if (states && states.length) {
        return states[0].codes.filter(s => s.id !== CodeTypes.ALL_STATES)
      }
      return []
    },
    clinics: state => {
      const clinics = state.codeTypes.filter(s => s.id === CodeTypes.CLINICS)
      if (clinics && clinics.length) {
        return clinics[0].codes.filter(code => code.status === Enums.Status.Active.value)
      }
      return []
    },
    studyLeavePrograms: state => {
      const programs = state.codeTypes.filter(s => s.id === CodeTypes.STUDYLEAVEPROGRAMS)
      if (programs && programs.length) {
        return programs[0].codes
      }
      return []
    }
  },
  mutations: {
    [SET_CODETYPES](state, codeTypes) {
      state.codeTypes = [...codeTypes]
    }
  },
  actions: {
    async [FETCH_CODETYPES]({ commit, state }) {
      return new Promise((resolve, reject) => {
        codeTypeService.getAllCodeTypes().then((result) => {
          commit(SET_CODETYPES, result.data.items)
          resolve()
        }).catch((error) => {
          reject(error)
        })
      })
    }
  }
}
