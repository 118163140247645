'use strict'

import BaseService from './baseService'
import { Constants } from '../utils/constants'

const serviceURL = `/api/${Constants.BASE_API_VERSION}/vacationRequests`

export default class VacationRequestService extends BaseService {
  async getVacationRequestsFor(employeeId, vacationYear) {
    let result = await this.sendRequest('GET', `${serviceURL}/${employeeId}/${vacationYear}`)
    return result.data
  }

  async getVacationRequestsForRecall(employeeId, vacationYear) {
    let result = await this.sendRequest('GET', `${serviceURL}/recall/${employeeId}/${vacationYear}`)
    return result.data
  }

  async getVacationRequestsForHolidaysNotTaken(employeeId, vacationYear) {
    let result = await this.sendRequest('GET', `${serviceURL}/holidaysNotTaken/${employeeId}/${vacationYear}`)
    return result.data
  }

  async saveVacationRecallRequest (recallRequest) {
    await this.sendRequest('POST', `${serviceURL}/recall`, recallRequest)
  }
}
