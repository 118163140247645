import Vue from 'vue'
import App from './App.vue'
import Error from './Error.vue'
import router from './router'
import store from './store'
import eventBus from '@/utils/eventBus'

import AdalAuthentication from '@/adal-auth'
import { GET_CURRENT_USER, FETCH_APPSETTINGS } from '@/store/action-type'
import { Keys, ActionTypes } from '@/utils/constants'

import AccountService from '@/services/accountService'

Vue.config.productionTip = false

const showApp = () => {
  new Vue({
    router,
    store,
    data: {
      eventBus //: new Vue()
    },
    provide() {
      return {
        eventBus: this.eventBus
      }
    },
    render: h => h(App)
  }).$mount('#app')
}

const showError = (error) => {
  new Vue({
    data: {
      error
    },
    provide () {
      return {
        eventBus: this.eventBus
      }
    },
    render: h => h(Error)
  }).$mount('#app')
}

AdalAuthentication.initialize()
  //.then(() => AdalAuthentication.acquireToken(Keys.MS_GRAPH_CLIENT_ID))
  //.then((msGraphToken) => (new AccountService()).login(msGraphToken))
  .then((result) => {
    const tasks = []
    tasks.push(store.dispatch(GET_CURRENT_USER))
    tasks.push(store.dispatch(FETCH_APPSETTINGS))
    return Promise.all(tasks)
  })
  .then(showApp)
  .catch((error) => {
    //console.log(error)
    showError(error)
  })
