export default {
  state: {
    sickLeaveClassifications: [{
      id: 1,
      name: 'Occupational Injury'
    },
    {
      id: 2,
      name: 'Occupational Illness'
    },
    {
      id: 3,
      name: 'Non Occupational Injury'
    },
    {
      id: 4,
      name: 'Non Occupational Illness'
    }
    ]
  },
  mutations: {

  },
  actions: {
  }
}
