'use strict'

import BaseService from './baseService'
import { Constants } from '../utils/constants'

const serviceURL = `/api/${Constants.BASE_API_VERSION}/maternityLeaveRequests`

export default class MaternityLeaveRequestService extends BaseService {
  async getLastMaternityLeaveForEmployee(employeeId) {
    let result = await this.sendRequest('GET', `${serviceURL}/${employeeId}/lastRequest`)
    return result.data
  }
}
