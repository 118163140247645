'use strict'

import BaseService from './baseService'
import { Constants } from '../utils/constants'

const serviceURL = `/api/${Constants.BASE_API_VERSION}`

export default class VacationBuyBackRequestService extends BaseService {
  async getRequestsPendingUserAction (employeeId) {
    var result = await this.sendRequest('GET', `${serviceURL}/buyBackRequests/Pending/${employeeId}`)
    return result.data
  }

  async getRequests(employeeId, year) {
    var result = await this.sendRequest('GET', `${serviceURL}/buyBackRequests/${employeeId}/${year}`)
    return result.data
  }

  async schedule(request) {
    const result = await this.sendRequest('POST', `${serviceURL}/${request.requestType}Requests`, request)
    return result.data
  }
}
