import { MutationTypes } from '@/utils/constants'

export default {
  state: {
    currentAppLayout: 'layout12'
  },
  mutations: {
    [MutationTypes.SET_LAYOUT] (state, layout) {
      state.currentAppLayout = layout
    }
  },
  getters: {
    currentAppLayout (state) {
      return state.currentAppLayout
    }
  }
}
