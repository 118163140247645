'use strict'

import BaseService from './baseService'
import { Constants } from '../utils/constants'

const serviceURL = `/api/${Constants.BASE_API_VERSION}/codeTypes`

export default class CodeTypeService extends BaseService {
  async getAllCodeTypes() {
    return this.sendRequest('GET', `${serviceURL}`)
  }
}
