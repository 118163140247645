'use strict'

import BaseService from './baseService'
import { Constants } from '../utils/constants'

const serviceURL = `/api/${Constants.BASE_API_VERSION}/vacationAllowance`

export default class VacationAllowanceService extends BaseService {
  async scheduleVacationAllowance (employeeId, vacationYear, payMonth) {
    const body = {
      employeeId,
      vacationYear,
      payMonth
    }
    await this.sendRequest('POST', `${serviceURL}`, body)
  }
}
