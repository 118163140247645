'use strict'

import BaseService from './baseService'
import { Constants } from '../utils/constants'

const serviceURL = `/api/${Constants.BASE_API_VERSION}/reports`

export default class ReportService extends BaseService {

  async searchVacationAllowance(searchParams) {
    const payMonth = searchParams.payMonth || ''
    const status = searchParams.status === 0 ? 0 : searchParams.status || ''
    const url = `${serviceURL}/vacationAllowance/search?payMonth=${payMonth}&status=${status}`
    let result = await this.sendRequest('GET', encodeURI(url))
    return result.data
  }

  async searchCashInLieuOfVacationRequests(searchParams) {
    const requestType = searchParams.requestType || ''
    const vacationYear = searchParams.vacationYear || ''
    const status = searchParams.status === 0 ? 0 : searchParams.status || ''
    const url = `${serviceURL}/cashInLieuOfVacation/search?requestType=${requestType}&vacationYear=${vacationYear}&status=${status}`
    let result = await this.sendRequest('GET', encodeURI(url))
    return result.data
  }

  async searchLeaveRequests(searchParams) {
    const leaveType = searchParams.leaveType || ''
    const vacationYear = searchParams.vacationYear || ''
    const status = searchParams.status === 0 ? 0 : searchParams.status || ''
    const employeeId = searchParams.employeeId || ''
    const includeDirectReports = searchParams.includeDirectReports || false
    const url = `${serviceURL}/leaveRequests/search?leaveType=${leaveType}&vacationYear=${vacationYear}&status=${status}&employeeId=${employeeId}&includeDirectReports=${includeDirectReports}`
    let result = await this.sendRequest('GET', encodeURI(url))
    return result.data
  }

  async searchMedicalLeaveRequests(searchParams) {
    const leaveType = searchParams.leaveType || ''
    const vacationYear = searchParams.vacationYear || ''
    const status = searchParams.status === 0 ? 0 : searchParams.status || ''
    const employeeId = searchParams.employeeId || ''
    const url = `${serviceURL}/medicalLeaveRequests/search?leaveType=${leaveType}&vacationYear=${vacationYear}&status=${status}&employeeId=${employeeId}`
    let result = await this.sendRequest('GET', encodeURI(url))
    return result.data
  }

  async searchVacationData(searchParams) {
    const vacationYear = searchParams.vacationYear || ''
    const employeeId = searchParams.employeeId || ''
    const includeDirectReports = searchParams.includeDirectReports || false
    const url = `${serviceURL}/vacationdata/search?vacationYear=${vacationYear}&employeeId=${employeeId}&includeDirectReports=${includeDirectReports}`
    let result = await this.sendRequest('GET', encodeURI(url))
    return result.data
  }
  
}
