'use strict'

import BaseService from './baseService'
import { Constants } from '../utils/constants'

const serviceURL = `/api/${Constants.BASE_API_VERSION}/paternityLeaveRequests`

export default class PaternityLeaveRequestService extends BaseService {
  async getPaternityLeaveRequestsFor(employeeId) {
    let result = await this.sendRequest('GET', `${serviceURL}/all/${employeeId}`)
    return result.data
  }
}
