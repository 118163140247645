<script>
  export default {
    mounted() {
      var ua = window.navigator.userAgent;
      var msie = ua.indexOf("MSIE ");
      if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./))      {
        alert('This application does not work with Internet explorer. Kindly use Microsoft Edge / Google Chrome');
      }
      return false;
    }
  }
</script>
