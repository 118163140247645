<template>
  <nav id="site-specific-nav">
    <!-- #region site-specific navigation -->
    <ul>
      <li>
        <a href="#leaveRequests">leave requests</a>
        <ul>
          <!--<li v-if="currentUser.isAdmin || currentUser.isExpatriateAdmin || currentUser.isResidentExpatriateEmployee"><router-link to="/requests/vacation">vacation</router-link></li>-->
          <!--<li><router-link to="/requests/fieldVacationScheduleOption">field schedule option</router-link></li>-->
          <!--<li><router-link to="/requests/personalBusinessDays">personal business days</router-link></li>-->
          <li v-if="currentUser.isAdmin || currentUser.isExpatriateAdmin || currentUser.isResidentExpatriateEmployee"><router-link to="/requests/rnr">r&amp;r request</router-link></li>
          <!--<li><router-link to="/requests/vacationAllowance">vacation allowance</router-link></li>
          <li v-if="currentUser.isAdmin || currentUser.canRaiseMedicalRequests"><router-link to="/requests/sickLeave">sick leave</router-link></li>
          <li v-if="currentUser.isAdmin || currentUser.canRaiseMedicalRequests"><router-link to="/requests/maternityLeave">maternity leave</router-link></li>
          <li><router-link to="/requests/paternityLeave">paternity leave</router-link></li>
          <li><router-link to="/requests/compassionateLeave">compassionate leave</router-link></li>
          <li><router-link to="/requests/studyLeave">study leave</router-link></li>
          <li><router-link to="/requests/leaveOfAbsence">leave of absence</router-link></li>
          <li v-if="currentUser.isAdmin || currentUser.isFieldEmployee"><router-link to="/requests/cashinlieuOfVacation">cash in lieu of vacation (field employees)</router-link></li>-->
        </ul>
      </li>
      <li>
        <a href="#manageLeaveRequests">manage requests</a>
        <ul>
          <li><router-link to="/requests/view">my requests</router-link></li>
          <li><router-link to="/requests/pending">requests pending my action</router-link></li>
          <!--<li><router-link to="/requests/vacation/recall">recall from vacation</router-link></li>
          <li><router-link to="/requests/vacation/holidaysNotTaken">public holidays not taken</router-link></li>
          <li><router-link to="/requests/vacationCarryOver">vacation carry over</router-link></li>-->
          <!--<li><router-link to="/requests/cashinlieuOfVacation">request cash in lieu of vacation</router-link></li>-->
        </ul>
      </li>
      <li v-if="currentUser.isAdmin || currentUser.canManageUsers || currentUser.canManageRoles || currentUser.canManagePublicHolidays || currentUser.canAdministerBenefits || currentUser.isExpatriateAdmin">
        <a href="#admin">admin</a>
        <ul>
          <li v-if="currentUser.isAdmin || currentUser.canManageUsers"><router-link to="/admin/users">user administration</router-link></li>
          <li v-if="currentUser.isAdmin || currentUser.canManageRoles"><router-link to="/admin/roles">role administration</router-link></li>
          <li v-if="currentUser.isAdmin || currentUser.canManagePublicHolidays"><router-link to="/admin/publicHolidays">public holidays</router-link></li>
          <!--<li v-if="currentUser.isAdmin || currentUser.canAdministerBenefits"><router-link to="/requests/vacationBuyBack">vacation buy back</router-link></li>-->
          <!--<li v-if="currentUser.isAdmin || currentUser.canAdministerBenefits || currentUser.isExpatriateAdmin"><router-link to="/requests/vacationDaysAdjustment">adjust vacation days</router-link></li>-->
          <!--<li v-if="currentUser.isAdmin || currentUser.canAdministerBenefits"><router-link to="/requests/loa/extend">extend study leave / leave of absence</router-link></li>-->
          <!--<li v-if="currentUser.isAdmin || currentUser.canAdministerBenefits"><router-link to="/requests/loa/close">resumption from study leave / leave of absence</router-link></li>-->
          <li v-if="currentUser.isAdmin || currentUser.canAdministerBenefits || currentUser.isExpatriateAdmin"><router-link to="/admin/vacationMaster/generate">generate vacation data</router-link></li>
        </ul>
      </li>
      <li>
        <a href="#reports">reports</a>
        <ul>
          <li v-if="currentUser.isAdmin || currentUser.canAdministerBenefits"><router-link to="/reports/vacationAllowance/search">vacation allowance search</router-link></li>
          <li v-if="currentUser.isAdmin || currentUser.canAdministerBenefits"><router-link to="/reports/cashInLieuOfVacation/search">cash in lieu of vacation / buy back</router-link></li>
          <li v-if="currentUser.isAdmin || currentUser.canAdministerBenefits"><router-link to="/reports/leaveRequest/search">search leave requests</router-link></li>
          <li v-if="currentUser.isAdmin || currentUser.canRunMedicalReports"><router-link to="/reports/medicalLeaveRequest/search">search medical leave requests</router-link></li>
          <li><router-link to="/reports/myLeaveRequests/search">my leave requests (includes direct reports)</router-link></li>
          <li v-if="currentUser.isAdmin || currentUser.canAdministerBenefits"><router-link to="/reports/vacationdata/search">vacation data report</router-link></li>
        </ul>
      </li>
    </ul>
    <!-- #endregion site-specific navigation -->
  </nav>
</template>

<script>

  import store from '@/store'
  import { mapState } from 'vuex'

  export default {
    name: 'AppNavigation',
    computed: {
      ...mapState({
        currentUser: state => state.userModule.currentUser,
      })
    }
  }
</script>

<style scoped lang="scss">
</style>
