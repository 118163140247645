import AppSettingsService from './appSettingsService'
import EmployeeService from './employeeService'
import UserService from './userService'
import AccountService from './accountService'
import RoleService from './roleService'
import LeaveRequestService from './leaveRequestService'
import PersonalBusinessDaysRequestService from './personalBusinessDaysRequestService'
import CompassionateLeaveRequestService from './compassionateLeaveRequestService'
import MaternityLeaveRequestService from './maternityLeaveRequestService'
import PaternityLeaveRequestService from './paternityLeaveRequestService'
import StudyLeaveRequestService from './studyLeaveRequestService'
import LeaveOfAbsenceRequestService from './leaveOfAbsenceRequestService'
import CodeTypeService from './codeTypeService'
import PublicHolidayService from './publicHolidayService'
import VacationMasterService from './vacationMasterService'
import VacationRequestService from './vacationRequestService'
import VacationChangeRequestService from './vacationChangeRequestService'
import VacationBuyBackRequestService from './buyBackRequestService'
import CashInLieuRequestService from './cashInLieuRequestService'
import VacationDaysAdjustmentRequestService from './vacationDaysAdjustmentRequestService'
import RnRRequestService from './rnrRequestService'
import LOARequestService from './loaRequestService'
import VacationAllowanceService from './vacationAllowanceService'
import ReportService from './reportService'

export const appSettingsService = new AppSettingsService()
export const employeeService = new EmployeeService()
export const userService = new UserService()
export const accountService = new AccountService()
export const roleService = new RoleService()
export const leaveRequestService = new LeaveRequestService()
export const personalBusinessDaysRequestService = new PersonalBusinessDaysRequestService()
export const compassionateLeaveRequestService = new CompassionateLeaveRequestService()
export const maternityLeaveRequestService = new MaternityLeaveRequestService()
export const paternityLeaveRequestService = new PaternityLeaveRequestService()
export const studyLeaveRequestService = new StudyLeaveRequestService()
export const leaveOfAbsenceRequestService = new LeaveOfAbsenceRequestService()
export const codeTypeService = new CodeTypeService()
export const publicHolidayService = new PublicHolidayService()
export const vacationMasterService = new VacationMasterService()
export const vacationRequestService = new VacationRequestService()
export const vacationChangeRequestService = new VacationChangeRequestService()
export const cashInLieuRequestService = new CashInLieuRequestService()
export const buyBackRequestService = new VacationBuyBackRequestService()
export const vacationDaysAdjustmentRequestService = new VacationDaysAdjustmentRequestService()
export const rnrRequestService = new RnRRequestService()
export const loaRequestService = new LOARequestService()
export const vacationAllowanceService = new VacationAllowanceService()
export const reportService = new ReportService()

