import {
  APPROVE_REQUEST,
  REJECT_REQUEST,
  RETURN_REQUEST,
  CANCEL_REQUEST,
  PROCESS_REQUEST
} from '@/store/action-type'

const RequestState = {
  New: 0,
  Submitted: 1
}

const Gender = {
  Male: {
    value: 'M',
    description: 'Male'
  },
  Female: {
    value: 'F',
    description: 'Female'
  }
}


const Status = {
  Inactive: {
    value: 0,
    description: 'Not Active',
  },
  Active: {
    value: 1,
    description: 'Active'
  }
}

const RequestType = {
  PersonalBusinessDays: {
    value: 'PersonalBusinessDays',
    description: 'Personal Business Days',
    type: 'leaveRequest',
    searchValue: 'PersonalBusinessDayRequest'
  },
  SickLeave: {
    value: 'SickLeave',
    description: 'Sick Leave',
    type: 'leaveRequest',
    searchValue: 'SickLeaveRequest'
  },
  CompassionateLeave: {
    value: 'CompassionateLeave',
    description: 'Compassiona Leave',
    type: 'leaveRequest',
    searchValue: 'CompassionateLeaveRequest'
  },
  MaternityLeave: {
    value: 'MaternityLeave',
    description: 'Maternity Leave',
    type: 'leaveRequest',
    searchValue: 'MaternityLeaveRequest'
  },
  PaternityLeave: {
    value: 'PaternityLeave',
    description: 'Paternity Leave',
    type: 'leaveRequest',
    searchValue: 'PaternityLeaveRequest'
  },
  StudyLeave: {
    value: 'StudyLeave',
    description: 'Study Leave',
    type: 'leaveRequest',
    searchValue: 'StudyLeaveRequest'
  },
  LeaveOfAbsence: {
    value: 'LeaveOfAbsence',
    description: 'Leave Of Absence',
    type: 'leaveRequest',
    searchValue: 'LeaveOfAbsenceRequest'
  },
  RnR: {
    value: 'RnR',
    description: 'R & R',
    type: 'leaveRequest',
    searchValue: 'RnRRequest'
  },
  Vacation: {
    value: 'Vacation',
    description: 'Vacation',
    type: 'leaveRequest',
    searchValue: 'VacationRequest'
  },
  VacationDaysRecall: {
    value: 'VacationDaysRecall',
    description: 'Vacation Recall',
    type: 'changeRequest'
  },
  HolidayNotTaken: {
    value: 'HolidayNotTaken',
    description: 'Holiday Not Taken',
    type: 'changeRequest'
  },
  CashInLieuOfVacation: {
    value: 'CashInLieuOfVacation',
    description: 'Cash In Lieu',
    type: 'changeRequest'
  },
  VacationDaysCarryOver: {
    value: 'VacationDaysCarryOver',
    description: 'Vacation Days Carry Over',
    type: 'changeRequest'
  },
  BuyBack: {
    value: 'BuyBack',
    description: 'Vacation buy back',
    type: 'changeRequest'
  },
  AdjustmentAddition: {
    value: 'AdjustmentAddition',
    description: 'Vacation Adjustment(Addition)',
    type: 'changeRequest'
  },
  AdjustmentRemoval: {
    value: 'AdjustmentRemoval',
    description: 'Vacation Adjustment(Removal)',
    type: 'changeRequest'
  },
  //VacationDaysAdjustment: {
  //  value: 'VacationDaysAdjustment',
  //  description: 'Vacation Adjustment',
  //  type: 'changeRequest'
  //},
  AdjustmentRnRRemoval: {
    value: 'AdjustmentRnRRemoval',
    description: 'R&R Adjustment(Removal)',
    type: 'changeRequest'
  },
  VacationAccruedUsage: {
    value: 'VacationAccruedUsage',
    description: 'Vacation Accrued Usage',
    type: 'changeRequest'
  },
  VacationAllowance: {
    value: 'VacationAllowance',
    description: 'Vacation Allowance',
    type: 'allowanceRequest'
  }
}

const VacationDaysChangeRequestType = {
  CarryOver: {
    value: 0,
    description: 'Vacation Days Carry Over'
  },
  Recall: {
    value: 1,
    description: 'Vacation Recall'
  },
  HolidaysNotTaken: {
    value: 2,
    description: 'Holiday Not Taken'
  }
}

const RequestStatus = {
  Submitted: {
    value: 10,
    description: 'Submitted'
  },
  Edited: {
    value: 12,
    description: 'Edited'
  },
  Returned: {
    value: 15,
    description: 'Returned'
  },
  ChangeRequested: {
    value: 20,
    description: 'Change Requested'
  },
  ChangePendingApproval: {
    value: 25,
    description: 'Change Pending Approval'
  },
  Approving: {
    value: 30,
    description: 'Approving'
  },
  PendingDepartmentHead: {
    value: 40,
    description: 'Pending Department Head'
  },
  PendingHRDirector: {
    value: 50,
    description: 'Pending HR Director'
  },
  PendingBenefits: {
    value: 90,
    description: 'Pending Benefits'
  },
  PendingHRIT: {
    value: 85,
    description: 'Pending HRIT'
  },
  Approved: {
    value: 70,
    description: 'Approved'
  },
  Closed: {
    value: 90,
    description: 'Closed'
  },
  Cancelled: {
    value: 98,
    description: 'Cancelled'
  },
  Rejected: {
    value: 99,
    description: 'Rejected'
  },
  Processed: {
    value: 100,
    description: 'Processed'
  }
}

const ChangeRequestStatus = {
  Submitted: {
    value: 0,
    description: 'Submitted'
  },
  Approving: {
    value: 1,
    description: 'Approving'
  },
  Approved: {
    value: 2,
    description: 'Approved'
  },
  Rejected: {
    value: 3,
    description: 'Rejected'
  },
  Cancelled: {
    value: 4,
    description: 'Cancelled'
  }
}

const Actions = {
  Approve: {
    value: 0,
    description: 'approve',
    actionName: APPROVE_REQUEST,
    postActionText: 'approved'
  },
  Reject: {
    value: 1,
    description: 'reject',
    actionName: REJECT_REQUEST,
    postActionText: 'rejected'
  },
  Return: {
    value: 2,
    description: 'return',
    actionName: RETURN_REQUEST,
    postActionText: 'returned'
  },
  Cancel: {
    value: 3,
    description: 'cancel',
    actionName: CANCEL_REQUEST,
    postActionText: 'cancelled'
  },
  Process: {
    value: 3,
    description: 'process',
    actionName: PROCESS_REQUEST,
    postActionText: 'processed'
  }
}

const UserReportRights = {
  ViewTeamReport: {
    value: 32,
    description: 'View team report'
  },
  ViewSectionReport: {
    value: 64,
    description: 'View section reports'
  },
  ViewDivisionReport: {
    value: 128,
    description: 'View division reports'
  },
  ViewDepartmentReport: {
    value: 256,
    description: 'View department reports'
  },
  ViewAllReport: {
    value: 512,
    description: 'View all reports'
  }
}

//const UserRights = {
//  EditTeamData: {
//    value: 1,
//    description: 'Edit team data',
//    employeeSearchFilterProperty: 'groupId'
//    //employeeSearchFilterProperty: ''
//  },
//  EditSectionData: {
//    value: 2,
//    description: 'Edit section data',
//    employeeSearchFilterProperty: 'sectionId'
//    //employeeSearchFilterProperty: ''
//  },
//  EditDivisionData: {
//    value: 4,
//    description: 'Edit division data',
//    employeeSearchFilterProperty: 'divisionId'
//  },
//  EditDepartmentData: {
//    value: 8,
//    description: 'Edit department data',
//    employeeSearchFilterProperty: 'departmentId'
//  },
//  EditAllData: {
//    value: 16,
//    description: 'Edit all data'
//  },
//  ViewTeamReport: {
//    value: 32,
//    description: 'View team report'
//  },
//  ViewSectionReport: {
//    value: 64,
//    description: 'View section reports'
//  },
//  ViewDivisionReport: {
//    value: 128,
//    description: 'View division reports'
//  },
//  ViewDepartmentReport: {
//    value: 256,
//    description: 'View department reports'
//  },
//  ViewAllReport: {
//    value: 512,
//    description: 'View department reports'
//  },
//  SystemAdministration: {
//    value: 1024,
//    description: 'System Administration'
//  }
//}

const RoleRights = {
  ManageRoles: {
    value: 1,
    description: 'Manage roles',
    //employeeSearchFilterProperty: ''
  },
  ManagerUsers: {
    value: 2,
    description: 'Manage users',
    //employeeSearchFilterProperty: ''
  },
  AdministerPublicHolidays: {
    value: 4,
    description: 'Manage public holidays'
  },
  RaiseMedicalRequests: {
    value: 1024,
    description: 'Raise medical requests'
  },
  IsPhysician: {
    value: 2048,
    description: 'Perform physician actions'
  },
  ReceiveMedicalRequestNotifications: {
    value: 4096,
    description: 'Receive Medical Request Notifications'
  },
  CanAdministerBenefits: {
    value: 8192,
    description: 'Perform Benefits Administration'
  },
  CanAdministerExpatriates: {
    value: 16384,
    description: 'Perform Expatriate Administration'
  },
  IsHRDirectorRole: {
    value: 32768,
    description: 'Is HR Director ROle'
  },
  IsHRITRole: {
    value: 65536,
    description: 'Is HR IT ROle'
  },
  IsBenefitAdminSupervisorRole: {
    value: 131072,
    description: 'Is Benefit Admin Supervisor Role'
  },
  IsHRITSupervisorRole: {
    value: 262144,
    description: 'Is HRIT Supervisor Role'
  },
  CanRunMedicalReports: {
    value: 524288,
    description: 'Can Run Medical Reports'
  }
}

const SickLeaveClassifications = {
  OccupationalInjury: {
    value: 0,
    description: 'Occupational Injury'
  },
  OccupationalIllness: {
    value: 1,
    description: 'Occupational Illness'
  },
  NonOccupationalInjury: {
    value: 2,
    description: 'Non Occupational Injury'
  },
  NonOccupationalIllnes: {
    value: 3,
    description: 'Non Occupational Illness'
  }
}

const MaternityTypes = {
  Delivery: {
    value: 0,
    description: 'Due to baby Delivery',
    numberOfDays: 84
  },
  Adoption: {
    value: 1,
    description: 'Due to Baby Adoption',
    numberOfDays: 42
  }
}

const CompassionateLeaveReasons = {
  ImmediateFamilyMemberSickness: {
    value: 0,
    description: 'Serious sickness of immediate family member'
  },
  ImmediateFamilyMemberInjury: {
    value: 1,
    description: 'Injury of immediate family member'
  },
  ImmediateFamilyMemberDeath: {
    value: 2,
    description: 'Death of immediate family member'
  },
  Others: {
    value: 3,
    description: '*Others'
  }
}

const LeaveOfAbsenceReasons = {
  Personal: {
    value: 0,
    description: 'Personal'
  },
  VolunteerService: {
    value: 1,
    description: 'Volunteer Service'
  },
  Sabbatical: {
    value: 2,
    description: 'Sabbatical'
  },
  NGOActivity: {
    value: 3,
    description: 'Non-Governmental Organization Activity'
  },
  PublicService: {
    value: 4,
    description: 'Public Service'
  },
  CivicDuties: {
    value: 5,
    description: 'Civic Duties'
  },
  PoliticalElectionOrAppointment: {
    value: 6,
    description: 'Political Election/Appointment'
  },
  Others: {
    value: 7,
    description: '*Others'
  }
}

const EmployeeType = {
  Regular: {
    value: '1',
    description: 'Regular'
  },
  Expatriate: {
    value: '2',
    description: 'Expatriate'
  },
  ContractNational: {
    value: '3',
    description: 'ContractNational'
  },
  ContractExpatriate: {
    value: '4',
    description: '*ContractExpatriate'
  },
  Temporal: {
    value: '7',
    description: '*Temporal'
  }
}

const WorkSchedule = {
  WS5x2: {
    value: '',
    description: 'Standard Work Schedule (Nigeria'
  },
  WS28x28: {
    value: 'A',
    description: '28x28 Rotation (Nigeria)'
  },
  WS14x14: {
    value: 'B',
    description: '14x14 Rotation (Nigeria)'
  }
}

const WorkLocation = {
  ForeignService: {
    value: '029',
    description: 'Foreign Service'
  }
}

const FieldVacationScheduleOption = {
  Schedule28_Days: {
    value: 1,
    description: 'Schedule - 28 Time on days',
    action: 'forfeitted'
  },
  Schedule14_Days: {
    value: 2,
    description: 'Schedule - 14 Time on days',
    action: 'paid cash in-lieu'
  },
  Schedule_CashInLieu: {
    value: 3,
    description: 'Cash In-lieu',
    action: ''
  }
}

const Months = {
  January: {
    value: 1,
    description: 'Jan'
  },
  February: {
    value: 2,
    description: 'Feb'
  },
  March: {
    value: 3,
    description: 'Mar'
  },
  April: {
    value: 4,
    description: 'Apr'
  },
  May: {
    value: 5,
    description: 'May'
  },
  June: {
    value: 6,
    description: 'Jun'
  },
  July: {
    value: 7,
    description: 'Jul'
  },
  August: {
    value: 8,
    description: 'Aug'
  },
  September: {
    value: 9,
    description: 'Sep'
  },
  October: {
    value: 10,
    description: 'Oct'
  },
  November: {
    value: 11,
    description: 'Nov'
  },
  December: {
    value: 12,
    description: 'Dec'
  }
}

const AllowancePaymentStatus = {
  Rejected: {
    value: 0,
    description: 'Rejected'
  },
  Requested: {
    value: 1,
    description: 'Requested'
  },
  Processed: {
    value: 2,
    description: 'Processed'
  },
  Paid: {
    value: 3,
    description: 'Paid'
  }
}

const AllowanceDisbursementModes = {
  USDAccountDeposit: {
    value: 0,
    description: 'Deposit to USD A/C'
  },
  ECashCard: {
    value: 1,
    description: 'Load Naira(equiv) on eCash card'
  }
}

class Enums {
  static convertToEnum(enumType, value) {
    for (const enumProperty in enumType) {
      if (enumType[enumProperty].value === value) {
        return enumType[enumProperty]
      }
    }
    return null
  }
}

Object.freeze(RequestState)
Object.freeze(RequestStatus)
Object.freeze(ChangeRequestStatus)
Object.freeze(Status)
Object.freeze(Gender)
Object.freeze(Actions)
Object.freeze(UserReportRights)
Object.freeze(RoleRights)
Object.freeze(SickLeaveClassifications)
Object.freeze(MaternityTypes)
Object.freeze(CompassionateLeaveReasons)
Object.freeze(LeaveOfAbsenceReasons)
Object.freeze(EmployeeType)
Object.freeze(WorkSchedule)
Object.freeze(WorkLocation)
Object.freeze(RequestType)
Object.freeze(VacationDaysChangeRequestType)
Object.freeze(FieldVacationScheduleOption)
Object.freeze(Months)
Object.freeze(AllowancePaymentStatus)
Object.freeze(AllowanceDisbursementModes)

Object.defineProperty(Enums, 'RequestState', {
  value: RequestState,
  writable: false
})

Object.defineProperty(Enums, 'Status', {
  value: Status,
  writable: false
})

Object.defineProperty(Enums, 'Gender', {
  value: Gender,
  writable: false
})

Object.defineProperty(Enums, 'RequestStatus', {
  value: RequestStatus,
  writable: false
})

Object.defineProperty(Enums, 'ChangeRequestStatus', {
  value: ChangeRequestStatus,
  writable: false
})

Object.defineProperty(Enums, 'Actions', {
  value: Actions,
  writable: false
})

Object.defineProperty(Enums, 'UserReportRights', {
  value: UserReportRights,
  writable: false
})

Object.defineProperty(Enums, 'RoleRights', {
  value: RoleRights,
  writable: false
})

Object.defineProperty(Enums, 'SickLeaveClassifications', {
  value: SickLeaveClassifications,
  writable: false
})

Object.defineProperty(Enums, 'MaternityTypes', {
  value: MaternityTypes,
  writable: false
})

Object.defineProperty(Enums, 'CompassionateLeaveReasons', {
  value: CompassionateLeaveReasons,
  writable: false
})

Object.defineProperty(Enums, 'LeaveOfAbsenceReasons', {
  value: LeaveOfAbsenceReasons,
  writable: false
})

Object.defineProperty(Enums, 'EmployeeType', {
  value: EmployeeType,
  writable: false
})

Object.defineProperty(Enums, 'WorkSchedule', {
  value: WorkSchedule,
  writable: false
})

Object.defineProperty(Enums, 'WorkLocation', {
  value: WorkLocation,
  writable: false
})

Object.defineProperty(Enums, 'RequestType', {
  value: RequestType,
  writable: false
})

Object.defineProperty(Enums, 'VacationDaysChangeRequestType', {
  value: VacationDaysChangeRequestType,
  writable: false
})

Object.defineProperty(Enums, 'FieldVacationScheduleOption', {
  value: FieldVacationScheduleOption,
  writable: false
})

Object.defineProperty(Enums, 'Months', {
  value: Months,
  writable: false
})

Object.defineProperty(Enums, 'AllowanceDisbursementModes', {
  value: AllowanceDisbursementModes,
  writable: false
})

Object.defineProperty(Enums, 'AllowancePaymentStatus', {
  value: AllowancePaymentStatus,
  writable: false
})

Object.freeze(Enums)
export default Enums
