'use strict'

import BaseService from './baseService'
import { Constants } from '../utils/constants'

const serviceURL = `/api/${Constants.BASE_API_VERSION}/account`

export default class AccountService extends BaseService {
  async login (msGraphToken) {
    return this.sendRequest('POST', `${serviceURL}/SignIn`, null, { 'MsGraph-Auth-Token': msGraphToken })
  }
}
