'use strict'

import BaseService from './baseService'
import { Constants } from '../utils/constants'

const serviceURL = `/api/${Constants.BASE_API_VERSION}/publicHolidays`

export default class PublicHolidayService extends BaseService {

  async getAllPublicHolidays(year) {
    let result = await this.sendRequest('GET', `${serviceURL}/${year}`)
    return result.data
  }

  async createPublicHoliday(publicHoliday) {
    let result = await this.sendRequest('POST', `${serviceURL}/`, publicHoliday)
    return result.data
  }

  async updatePublicHoliday(publicHoliday) {
    let result = this.sendRequest('PUT', `${serviceURL}/`, publicHoliday)
    return result.data
  }

  async deleteHoliday(holidayId) {
    await this.sendRequest('DELETE', `${serviceURL}/${holidayId}`)
  }
}
