'use strict'

import BaseService from './baseService'
import { Constants } from '../utils/constants'

const serviceURL = `/api/${Constants.BASE_API_VERSION}/leaveOfAbsenceRequests`

export default class LeaveOfAbsenceRequestService extends BaseService {
  async getAllLeaveOfAbsenceRequestsForEmployee(employeeId) {
    let result = await this.sendRequest('GET', `${serviceURL}/all/${employeeId}`)
    return result.data
  }
  async getLastLeaveOfAbsenceForEmployee(employeeId) {
    let result = await this.sendRequest('GET', `${serviceURL}/${employeeId}/lastRequest`)
    return result.data
  }
}
