'use strict'

import BaseService from './baseService'
import { Constants } from '../utils/constants'

const serviceURL = `/api/${Constants.BASE_API_VERSION}/vacationChangeRequests`

export default class VacationChangeRequestService extends BaseService {
  async getRequestsPendingUserAction (employeeId) {
    var result = await this.sendRequest('GET', `${serviceURL}/Pending/${employeeId}`)
    return result.data
  }

  async getRequests (employeeId, year) {
    var result = await this.sendRequest('GET', `${serviceURL}/${employeeId}/${year}`)
    return result.data
  }
}
