import Vue from 'vue'
import Enums from '@/utils/enums'
import { leaveRequestService } from '../../../services'
import {
  SCHEDULE_LEAVE_REQUEST,
  SAVE_EDITED_LEAVE_REQUEST,
  FETCH_USER_REQUESTS,
  FETCH_REQUESTS_PENDING_USER_ACTION,
  APPROVE_REQUEST,
  REJECT_REQUEST,
  RETURN_REQUEST,
  CANCEL_REQUEST,
  PROCESS_REQUEST,
  UPDATE_PENDING_REQUEST
} from '../../action-type'

import {
  SET_USER_REQUESTS,
  SET_REQUESTS_PENDING_USER_ACTION
} from '../../mutation-type'

export default {
  state: {
    userRequests: [],
    pendingUserAction: []
  },
  getters: {
  },
  mutations: {
    [SET_USER_REQUESTS](state, data) {
      state.userRequests.splice(0, state.userRequests.length)
      for (const request of data.requests) {
        state.userRequests.push(request)
      }
    },
    [SET_REQUESTS_PENDING_USER_ACTION](state, data) {
      state.pendingUserAction.splice(0, state.pendingUserAction.length)
      for (const request of data.requests) {
        state.pendingUserAction.push(request)
      }
    },
    [UPDATE_PENDING_REQUEST](state, { request }) {
      for (let i = 0; i < state.pendingUserAction.length; i++) {
        if (state.pendingUserAction[i].id === request.id) {
          state.pendingUserAction[i].status = request.status/*.value*/
          //Vue.set(state.pendingUserAction, i, data.request)
          state.pendingUserAction[i].intendedApproverId = request.intendedApproverId
          state.pendingUserAction[i].intendedApproverName = request.intendedApproverName
          state.pendingUserAction[i].intendedApproverRoleRight = request.intendedApproverRoleRight
        }
      }
      //state.pendingUserAction.set(data.request.id, data.request)
    }
  },
  actions: {
    async [SCHEDULE_LEAVE_REQUEST]({ commit }, data) {
      return new Promise((resolve, reject) => {
        leaveRequestService.schedule(data.request || data.requests, data.employeeId, data.vacationAllowanceMonth).then((result) => {
          resolve()
        }).catch((error) => {
          reject(error)
        })
      })
    },
    async [SAVE_EDITED_LEAVE_REQUEST]({ commit }, data) {
      return new Promise((resolve, reject) => {
        leaveRequestService.saveEditedRequest(data.request, data.comment).then((result) => {
          resolve()
        }).catch((error) => {
          reject(error)
        })
      })
    },
    [FETCH_USER_REQUESTS]({ state, commit, rootState }, data) {
      return new Promise((resolve, reject) => {
        leaveRequestService.getLeaveRequests(data.employeeId, data.year).then((result) => {
          const requests = result.items
          if (requests && requests.length) {
            requests.forEach(request => {
              request.employeeName = request.employeeInfo.fullName
            })
          }
          commit(SET_USER_REQUESTS, { requests })
          resolve()
        }).catch((error) => {
          reject(error)
        })
      })
    },
    [FETCH_REQUESTS_PENDING_USER_ACTION]({ state, commit, rootState }, data) {
      return new Promise((resolve, reject) => {
        leaveRequestService.getRequestsPendingUserAction(data.employeeId).then((result) => {
          const requests = result.items
          if (requests && requests.length) {
            requests.forEach(request => {
              request.employeeName = request.employeeInfo.fullName
            })
          }
          commit(SET_REQUESTS_PENDING_USER_ACTION, { requests })
          resolve()
        }).catch((error) => {
          reject(error)
        })
        //if (!data.refresh && state.pendingUserAction.has(data.employeeId)) {
        //  resolve(state.pendingUserAction.get(data.employeeId))
        //  return
        //}
        //leaveRequestService.getRequestsPendingUserAction(data.employeeId).then((result) => {
        //  const requests = result.items
        //  if (requests && requests.length) {
        //    requests.forEach(request => {
        //      request.employeeName = request.employeeInfo.fullName
        //    })
        //  }
        //  commit(SET_REQUESTS_PENDING_USER_ACTION, { currentUserEmployeeId: rootState.userModule.currentUser.employeeId, employeeId: data.employeeId, requests })
        //  resolve(state.pendingUserAction.get(data.employeeId))
        //}).catch((error) => {
        //  reject(error)
        //})
      })
    },

    async [APPROVE_REQUEST]({ state, commit, rootState }, { requestIds, requestType, comment }) {
      return new Promise((resolve, reject) => {
        leaveRequestService.approveRequest(requestIds, requestType, comment).then((result) => {
          //commit(UPDATE_PENDING_REQUEST, { request: result })
          resolve(result)
        }).catch((error) => {
          reject(error)
        })
      })
    },

    async [REJECT_REQUEST]({ state, commit, rootState }, { requestIds, requestType, comment }) {
      return new Promise((resolve, reject) => {
        leaveRequestService.rejectRequest(requestIds, requestType, comment).then((result) => {
          //commit(UPDATE_PENDING_REQUEST, { requestId, status: Enums.RequestStatus.Rejected.value })
          resolve(result)
        }).catch((error) => {
          reject(error)
        })
      })
    },

    async [RETURN_REQUEST]({ state, commit, rootState }, { requestIds, requestType, comment }) {
      return new Promise((resolve, reject) => {
        leaveRequestService.returnRequest(requestIds, requestType, comment).then((result) => {
          //commit(UPDATE_PENDING_REQUEST, { requestId, status: Enums.RequestStatus.Returned.value })
          resolve(result)
        }).catch((error) => {
          reject(error)
        })
      })
    },

    async [CANCEL_REQUEST]({ state, commit, rootState }, { requestIds, requestType, comment }) {
      return new Promise((resolve, reject) => {
        leaveRequestService.cancelRequest(requestIds, requestType, comment).then((result) => {
          //commit(UPDATE_PENDING_REQUEST, { requestId, status: Enums.RequestStatus.Cancelled.value })
          resolve(result)
        }).catch((error) => {
          reject(error)
        })
      })
    },

    async [PROCESS_REQUEST]({ state, commit, rootState }, { requestIds, requestType, comment }) {
      return new Promise((resolve, reject) => {
        leaveRequestService.processRequest(requestIds, requestType, comment).then((result) => {
          //commit(UPDATE_PENDING_REQUEST, { requestId, status: Enums.RequestStatus.Cancelled.value })
          resolve(result)
        }).catch((error) => {
          reject(error)
        })
      })
    }
  }
}
