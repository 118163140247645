export const FETCH_APPSETTINGS = 'fetchAppSettings'

export const GET_CURRENT_USER = 'getCurrentUser'

export const FETCH_ROLES = 'fetchRoles'
export const CREATE_ROLE = 'createRole'
export const UPDATE_ROLE = 'updateRole'
export const DELETE_ROLE = 'deleteRole'

export const FETCH_USERS = 'fetchUsers'
export const CREATE_USER = 'createUser'
export const UPDATE_USER = 'updateUser'
export const CHANGE_USER_STATUS = 'changeUserStatus'
//export const FETCH_CURRENT_USER_REQUESTS = 'fetchCurrentUserLeaveRequests'
//export const FETCH_USER_COMPASSIONATE_LEAVE_DATA = 'fetchUserCompassionateLeaveData'
export const FETCH_REQUESTS_PENDING_USER_ACTION = 'fetchRequestsPendingUserAction'
export const FETCH_USER_REQUESTS = 'fetchUserRequests'
export const SCHEDULE_LEAVE_REQUEST = 'scheduleLeaveRequest'
export const SAVE_EDITED_LEAVE_REQUEST = 'saveEditedLeaveRequest'
export const APPROVE_REQUEST = 'approveRequest'
export const REJECT_REQUEST = 'rejectRequest'
export const RETURN_REQUEST = 'returnRequest'
export const CANCEL_REQUEST = 'cancelRequest'
export const PROCESS_REQUEST = 'processRequest'
export const UPDATE_PENDING_REQUEST = 'updatePendingRequest'

export const FETCH_CODETYPES = 'fetchCodeTypes'

export const FETCH_PUBLICHOLIDAYS = 'fetchPublicHolidays'
export const CREATE_PUBLICHOLIDAY = 'createPublicHoliday'
export const UPDATE_PUBLICHOLIDAY = 'updatePublicHoliday'
export const DELETE_PUBLICHOLIDAY = 'deletePublicHoliday'
