'use strict'

import BaseService from './baseService'
import { Constants } from '../utils/constants'
import Enums from '@/utils/enums'

const serviceURL = `/api/${Constants.BASE_API_VERSION}`

export default class LeaveRequestService extends BaseService {

  async getRequests (employeeId, year) {
    var result = await this.sendRequest('GET', `${serviceURL}/leaveRequests/${employeeId}/${year}`)
    return result.data
  }

  async getLeaveRequest (leaveRequestType, requestId) {
    var result = await this.sendRequest('GET', `${serviceURL}/${leaveRequestType}Requests/${requestId}`)
    return result.data
  }

  async getRequestsInLastYears(leaveRequestType, employeeId, numberOfYears) {
    var result = await this.sendRequest('GET', `${serviceURL}/${leaveRequestType}Requests/requestsInLastYears/${employeeId}/${numberOfYears}`)
    return result.data
  }

  async getRequestsWithinDateRange(employeeId, startDate, endDate) {
    var result = await this.sendRequest('GET', `${serviceURL}/leaveRequests/overlappingRequests/${employeeId}/${startDate}/${endDate}`)
    return result.data
  }

  async getRequestsPendingUserAction (employeeId) {
    var result = await this.sendRequest('GET', `${serviceURL}/leaveRequests/Pending/${employeeId}`)
    return result.data
  }

  async schedule(leaveRequests, employeeId, vacationAllowanceMonth) {
    if (Array.isArray(leaveRequests)) {
      let requests = []
      for (const leaveRequest of leaveRequests) {
        let request = { ...leaveRequest }
        if (typeof request.id !== 'number') {
          delete request.id
        }
        request.employeeId = employeeId
        requests.push(request)
      }
      if (leaveRequests[0].requestType === Enums.RequestType.Vacation.value) {
        return this.sendRequest('POST', `${serviceURL}/vacationRequests`, { requests, vacationAllowanceMonth })
      }
      else {
        return this.sendRequest('POST', `${serviceURL}/${leaveRequests[0].requestType}Requests`, requests)
      }
    }
    else {
      let request = { ...leaveRequests }
      //alert(leaveRequests.requestType)
      //alert(request.requestType)
      //console.log(request)
      delete request.id
      request.employeeId = employeeId
      return this.sendRequest('POST', `${serviceURL}/${leaveRequests.requestType}Requests`, request)
    }
  }

  //async schedulePaternityRequest(leaveRequest, file) {
  //  let formData = new FormData();
  //  const config = {
  //    header: { 'content-type': 'multipart/form-data' }
  //  }
  //  formData.append('file', this.$refs.leaveEntryForm.$refs.birthCertificateDropzone.getQueuedFiles()[0])
  //  console.log(this.$refs.leaveEntryForm.$refs.birthCertificateDropzone.getQueuedFiles()[0])
  //  axios.request({
  //    method: 'post',
  //    url: '/api/v1/paternityleaverequest',
  //    data: formData,
  //    headers: { 'Content-Type': 'multipart/form-data' },
  //  }).then(response => {
  //    alert("Succeeded")
  //  }).catch((error) => {
  //    alert("Error")
  //  })
  //  //let request = { ...leaveRequest }
  //  //request.employeeId = request.employeeInfo.employeeId
  //  //request.employeeInfo = null
  //  //return this.sendRequest('POST', `${serviceURL}/${leaveRequest.type}Requests`, request)
  //}

  async saveEditedRequest (leaveRequest, comment) {
    let request = { ...leaveRequest }
    //request.employeeId = request.employeeInfo.employeeId
    request.employeeInfo = null
    if ([Enums.RequestType.StudyLeave.value, Enums.RequestType.LeaveOfAbsence.value].includes(request.requestType)) {
      return this.sendRequest('PUT', `${serviceURL}/${leaveRequest.requestType}Requests`, request)
    }
    else {
      return this.sendRequest('PUT', `${serviceURL}/${leaveRequest.requestType}Requests`, { request, comment })
    }
  }

  //async cancelRequest (requestId, comment) {
  //  var result = await this.sendRequest('PATCH', `${serviceURL}/leaveRequests/${requestId}`, { comment })
  //  return result.data
  //}

  async approveRequest(requestIds, requestType, comment) {
    var result = await this.sendRequest('PATCH', `${serviceURL}/${requestType}Requests/approve`, { requestIds, comment })
    return result.data
  }

  async rejectRequest(requestIds, requestType, comment) {
    var result = await this.sendRequest('PATCH', `${serviceURL}/${requestType}Requests/reject`, { requestIds, comment })
    return result.data
  }

  async returnRequest(requestIds, requestType, comment) {
    var result = await this.sendRequest('PATCH', `${serviceURL}/${requestType}Requests/return`, { requestIds, comment })
    return result.data
  }

  async cancelRequest(requestIds, requestType, comment) {
    var result = await this.sendRequest('PATCH', `${serviceURL}/${requestType}Requests/cancel`, { requestIds, comment })
    return result.data
  }

  async processRequest(requestIds, requestType, comment) {
    var result = await this.sendRequest('PATCH', `${serviceURL}/${requestType}Requests/process`, { requestIds, comment })
    return result.data
  }
  //async cancelRequest(requestId, leaveType, comment) {
  //  return this.sendRequest('PATCH', `${serviceURL}/${leaveType}Requests/cancel/${requestId}`, { comment })
  //}
}
