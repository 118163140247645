'use strict'

import BaseService from './baseService'
import { Constants } from '../utils/constants'

const serviceURL = `/api/${Constants.BASE_API_VERSION}/personalBusinessDaysRequests`

export default class PersonalBusinessDaysRequestService extends BaseService {
  async getNumberOfDaysTaken(employeeId, year) {
    let result = await this.sendRequest('GET', `${serviceURL}/DaysTaken/${employeeId}/${year}`)
    return result.data
  }
}
