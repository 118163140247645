import { compassionateLeaveRequestService } from '../../../services'
import {
  FETCH_USER_COMPASSIONATE_LEAVE_DATA
} from '../../action-type'

export default {
  state: {
  },
  getters: {

  },
  mutations: {

  },
  actions: {
    async [FETCH_USER_COMPASSIONATE_LEAVE_DATA] ({ commit }, { employeeId, year }) {
      return new Promise((resolve, reject) => {
        compassionateLeaveRequestService.getNumberOfDaysTaken(employeeId, year).then((result) => {
          resolve()
        }).catch((error) => {
          reject(error)
        })
      })
    }
  }
}
