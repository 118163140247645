export const Constants = {
  BASE_API_VERSION: 'v1'
}

export const CodeTypes = {
  STATES: 'STC',
  ALL_STATES: '*',
  CLINICS: 'CCL',
  STUDYLEAVEPROGRAMS: 'SLP'
}

export const LeaveTypes = {
  SickLeave: 'sickLeave',
  MaternityLeave: 'maternityLeave',
  PaternityLeave: 'paternityLeave',
  CompassionateLeave: 'compassionateLeave',
  StudyLeave: 'studyLeave',
  LeaveOfAbsence: 'leaveOfAbsence',
  PersonalBusinessDays: 'personalBusinessDays',
  RnR: 'RnR',
  Vacation: 'vacation'
}

export const RequestConstants = {
  TOTAL_NUMBER_OF_COMPASSIONATE_DAYS_ALLOWED: 5,
  MAXIMUM_NUMBER_OF_COMPASSIONATE_DAYS_FOR_SINGLE_REQUEST: 3,
  MAXIMUM_NUMBER_OF_SICK_DAYS_FOR_SINGLE_REQUEST: 30,
  MAXIMUM_NUMBER_OF_MATERNITY_DAYS_FOR_SINGLE_REQUEST: 84,
  MAXIMUM_NUMBER_OF_PATERNITY_DAYS_FOR_SINGLE_REQUEST: 5,
  MINIMIM_YEARS_OF_CONTINOUS_EMPLOYMENT_FOR_STUDY_LEAVE: 5,
  MINIMIM_YEARS_OF_CONTINOUS_EMPLOYMENT_FOR_LEAVE_OF_ABSENCE: 1,
  MINIMIM_NUMBER_OF_SUPPORTING_DOCUMENTS_FOR_STUDY_LEAVE: 2,
  MINIMIM_NUMBER_OF_SUPPORTING_DOCUMENTS_FOR_LEAVE_OF_ABSENCE: 1
}

export const EmployeeTypeConstants = {
  REGULAR: 1,
  EXPATRIATE: 2
}

export const WorkLocationConstants = {
  FOREIGN_SERVICE: '029'
}

export const WorkScheduleConstants = {
  NO_ROTATION: '',
  FOURTEEN_BY_FOURTEEN_ROTATION: '',
  TWENTY_EIGHT_BY_TWENTY_EIGHT_ROTATION: ''
}

export const MutationTypes = {
  SET_LAYOUT: 'setLayout',
  SET_CURRENT_USER: 'setCurrentUser'
}

export const ActionTypes = {
  GET_CURRENT_USER: 'getCurrentUser'
}

export const Keys = {
  MS_GRAPH_CLIENT_ID: 'https://graph.windows.net',
  MS_GRAPH_URL: 'https://graph.windows.net/chevron.com/me?api-version=1.6'
}

export const Layouts = {

}

export const Roles = {
  Physician: {
    id: 1,
    name: 'Physician'
  },
  HRIT: {
    id: 2,
    name: 'HR IT Administrator'
  }
}

export const ALERT_TYPE_ERROR = 'error'
export const ALERT_TYPE_SUCCESS = 'success'
export const FLOATING_FIELD_TEXTINPUT = 'text'
export const FLOATING_FIELD_TEXTAREA = 'textarea'

export const CLOSE_MODAL_EVENT = 'CLOSE_MODAL'

export const EMPLOYEE_SEARCH_STARTED_EVENT = 'EMPLOYEE_SEARCH_STARTED'
export const EMPLOYEE_SELECTED_EVENT = 'EMPLOYEE_SELECTED'
export const EMPLOYEE_SEARCH_CLEARED_EVENT = 'EMPLOYEE_SEARCH_CLEARED'
export const APPROVER_SEARCH_STARTED_EVENT = 'APPROVER_SEARCH_STARTED'
export const APPROVER_SELECTED_EVENT = 'APPROVER_SELECTED'
export const APPROVER_SEARCH_CLEARED_EVENT = 'APPROVER_SEARCH_CLEARED'

export const EDIT_USER_EVENT = 'EDIT_USER'
export const EDIT_ROLE_EVENT = 'EDIT_ROLE'
export const REMOVE_ROLE_EVENT = 'REMOVE_ROLE'
export const CHANGE_USER_STATUS_EVENT = 'CHANGE_USER_STATUS'
export const SELECT_REQUEST_EVENT = 'SELECT_REQUEST_EVENT'

export const EDIT_REQUEST_EVENT = 'EDIT_REQUEST_EVENT'
export const CANCEL_REQUEST_EVENT = 'CANCEL_REQUEST_EVENT'
export const APPROVE_REQUEST_EVENT = 'APPROVE_REQUEST_EVENT'
export const REJECT_REQUEST_EVENT = 'REJECT_REQUEST_EVENT'
export const RETURN_REQUEST_EVENT = 'RETURN_REQUEST_EVENT'
export const PROCESS_REQUEST_EVENT = 'PROCESS_REQUEST_EVENT'


export const EDIT_HOLIDAY_EVENT = 'EDIT_HOLIDAY_EVENT'
export const DELETE_HOLIDAY_EVENT = 'DELETE_HOLIDAY_EVENT'
