'use strict'

import BaseService from './baseService'
import { Constants } from '../utils/constants'

const serviceURL = `/api/${Constants.BASE_API_VERSION}/rnrRequests`

export default class RnRRequestService extends BaseService {
  async getAllRequestsFor (employeeId) {
    let result = await this.sendRequest('GET', `${serviceURL}/all/${employeeId}`)
    return result.data
  }

  async getAllowanceDetails(employeeId) {
    let result = await this.sendRequest('GET', `${serviceURL}/allowanceDetails/${employeeId}`)
    return result.data
  }

  async getRequestsPendingUserAction(employeeId) {
    var result = await this.sendRequest('GET', `${serviceURL}/Pending/${employeeId}`)
    return result.data
  }

  async getRequests(employeeId, year) {
    var result = await this.sendRequest('GET', `${serviceURL}/${employeeId}/${year}`)
    return result.data
  }
}
