'use strict'

import BaseService from './baseService'
import { Constants } from '../utils/constants'
import axios from 'axios'

const serviceURL = `/api/${Constants.BASE_API_VERSION}/loaRequests`

export default class LOARequestService extends BaseService {
  async getUnclosedRequests() {
    let result = await this.sendRequest('GET', `${serviceURL}/unclosedRequests`)
    return result.data
  }

  async extendRequest(requestId, request) {
    await this.sendRequest('PATCH', `${serviceURL}/extend/${requestId}`, request)
  }

  async closeOutRequest(requestId, request) {
    await this.sendRequest('PATCH', `${serviceURL}/closeOut/${requestId}`, request)
  }

  async downloadDocument(requestId, documentId) {
    let result = await this.sendRequest('GET', `${serviceURL}/downloadDocument/${requestId}/${documentId}`, null, null, 'blob')
    return result.data
  }

}
