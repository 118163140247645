'use strict'

import BaseService from './baseService'
import { Constants } from '../utils/constants'

const serviceURL = `/api/${Constants.BASE_API_VERSION}/applicationSettings`
export default class AppSetttingsService extends BaseService {
  async getApplicationSettings() {
    return this.sendRequest('GET', `${serviceURL}`)
  }
}
