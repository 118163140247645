'use strict'

import BaseService from './baseService'
import { Constants } from '../utils/constants'

const serviceURL = `/api/${Constants.BASE_API_VERSION}/vacationMasters`

export default class VacationMasterService extends BaseService {

  async getVacationMasterFor (employeeId, vacationYear) {
    var result = await this.sendRequest('GET', `${serviceURL}/${employeeId}/${vacationYear}`)
    return result.data
  }

  async getVacationMasterForVacationRequest (requestId) {
    var result = await this.sendRequest('GET', `${serviceURL}/requests/${requestId}`)
    return result.data
  }

  async createVacationMasterFor(request) {
    var result = await this.sendRequest('POST', `${serviceURL}`, request)
    return result.data
  }

  async createVacationMasterManuallyFor(request) {
    var result = await this.sendRequest('POST', `${serviceURL}/manual`, request)
    return result.data
  }

  async updateFieldVacationOptionFor(employeeId, vacationYear, fieldVacationOption) {
    var result = await this.sendRequest('PATCH', `${serviceURL}/${employeeId}/${vacationYear}/${fieldVacationOption}`)
    return result.data
  }
}
