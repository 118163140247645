import * as msal from '@azure/msal-browser'

const msalConfig = {
  auth: {
    clientId: process.env.VUE_APP_AZURE_APP_ID,
    authority: 'https://login.microsoftonline.com/fd799da1-bfc1-4234-a91c-72b3a1cb9e26/',
    redirectUri: window.location.origin
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
    secureCookies: false
  },
  system: {
    windowHashTimeout: 60000,
    iframeHashTimeout: 6000,
    loadFrameTimeout: 0
  }
}

//const config = {
//  tenant: 'fd799da1-bfc1-4234-a91c-72b3a1cb9e26',
//  clientId: process.env.VUE_APP_AZURE_APP_ID,
//  redirectUri: window.location.origin,
//  cacheLocation: 'localStorage',
//  loadFrameTimeout: 30000
//}

export default {
  msalInstance: null,
  account: null,
  accessToken: null,
  /**
   * @return {Promise}
   */
  async initialize () {
    try {
      this.msalInstance = new msal.PublicClientApplication(msalConfig)
      const tokenResponse = await this.msalInstance.handleRedirectPromise()
      if (tokenResponse) {
        this.account = tokenResponse.account
        return this.account
      } else {
        await this.signIn()
        return this.initialize()
      }
    } catch (error) {
      return error
    }
    //const accounts = this.msalInstance.getAllAccounts()
    //if (accounts.length == 0) {
    //  return null
    //}
    //else {
    //  this.account = accounts[0]
    //  return this.account
    //}
  },
  /**
 * @return {Promise.<String>} A promise that resolves to an ADAL token for resource access
 */
  async acquireToken (resourceId) {
    const tokenResponse = await this.msalInstance.handleRedirectPromise()
    if (tokenResponse) {
      return tokenResponse
    } else {
      var silentRequest = {
        scopes: ['user.read'],
        account: this.account,
        forceRefresh: false
      }
      return this.msalInstance.acquireTokenSilent(silentRequest)
    }
  },
  /**
   * Issue an interactive authentication request for the current user and the api resource.
   */
  async acquireTokenRedirect (resourceId) {
    const tokenResponse = await this.msalInstance.handleRedirectPromise()
    if (tokenResponse) {
      return tokenResponse.accessToken
    } else {
      var redirectRequest = {
        scopes: ['user.read'],
        account: this.account,
        forceRefresh: false
      }
      return this.msalInstance.acquireTokenRedirect(redirectRequest)
    }
  },
  async signIn () {
    try {
      await this.msalInstance.loginRedirect()
      const accounts = this.msalInstance.getAllAccounts()
      this.account = accounts[0]
    } catch (error) {
      alert(`error during authentication: ${error}`)
      return error
    }
  },

  async signOut () {
    await this.msalInstance.logoutRedirect()
  }
}
