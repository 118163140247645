import { appSettingsService } from '../../../services'
import {
  FETCH_APPSETTINGS
} from '../../action-type'
import {
  SET_APPSETTINGS
} from '../../mutation-type'

export default {
  state: {
    appSettings: {}
  },
  getters: {
  },
  mutations: {
    [SET_APPSETTINGS](state, appSettings) {
      Object.assign(state.appSettings, appSettings.data)
    }
  },
  actions: {
    async [FETCH_APPSETTINGS]({ commit }) {
      return new Promise((resolve, reject) => {
        appSettingsService.getApplicationSettings().then((result) => {
          commit(SET_APPSETTINGS, result)
          resolve()
        }).catch((error) => {
          reject(error)
        })
      })
    }
  }
}
