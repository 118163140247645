export const SET_APPSETTINGS = 'setAppSettings'

export const SET_CURRENT_USER = 'setCurrentUser'

export const SET_ROLES = 'setRoles'
export const ADD_ROLE = 'addRole'
export const EDIT_ROLE = 'editRole'
export const REMOVE_ROLE = 'removeRole'

export const SET_USERS = 'setUsers'
export const ADD_USER = 'addUser'
export const EDIT_USER = 'editUser'

export const SET_LAYOUT = 'setLayout'

export const SET_USER_REQUESTS = 'setUserRequests'
export const SET_REQUESTS_PENDING_USER_ACTION = 'setRequestsPendingUserAction'

export const SET_CODETYPES = 'setCodeTypes'

export const SET_PUBLICHOLIDAYS = 'setPublicHolidays'
export const ADD_PUBLICHOLIDAY = 'addublicHolidays'
export const EDIT_PUBLICHOLIDAY = 'editPublicHolidays'
export const REMOVE_PUBLICHOLIDAY = 'removePublicHolidays'
