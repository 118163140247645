'use strict'

import BaseService from './baseService'
import { Constants } from '../utils/constants'

const serviceURL = `/api/${Constants.BASE_API_VERSION}/users`

export default class UserService extends BaseService {
  async getCurrenUser() {
    return this.sendRequest('GET', `${serviceURL}/current`)
  }

  async getAllUsers () {
    return this.sendRequest('GET', `${serviceURL}`)
  }

  async createUser (user) {
    return this.sendRequest('POST', `${serviceURL}`, user)
  }

  async updateRole (user) {
    return this.sendRequest('PUT', `${serviceURL}`, user)
  }
}
