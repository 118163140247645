import Vue from 'vue'
import Vuex from 'vuex'

import AppSettingsModule from '@/store/modules/appSettings'
import LayoutModule from '@/store/modules/layout'
import UserModule from '@/store/modules/user'
import RoleModule from '@/store/modules/role'
import ClinicModule from '@/store/modules/clinic'
import SickLeaveClassificationModule from '@/store/modules/sickLeaveClassification'
import LeaveRequestModule from '@/store/modules/leaveRequest'
import CompassionateLeaveRequestModule from '@/store/modules/compassionateLeaveRequest'
import CodeTypeModule from '@/store/modules/codeType'
import PublicHolidayModule from '@/store/modules/publicHoliday'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    applicationSettingsModule: AppSettingsModule,
    layout: LayoutModule,
    userModule: UserModule,
    roleModule: RoleModule,
    //clinicModule: ClinicModule,
    sickLeaveClassificationModule: SickLeaveClassificationModule,
    leaveRequestModule: LeaveRequestModule,
    compassionateLeaveRequestModule: CompassionateLeaveRequestModule,
    codeTypeModule: CodeTypeModule,
    publicHolidayModule: PublicHolidayModule
  }
})
