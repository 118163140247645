<template>
  <div class="home">
    <Welcome :msg="msg" />
    <div class="row">
      <div class="col">
        <div class="row">
          <div class="col-9 mr-0">
            <img src="../assets/banner.gif" alt="banner" />
          </div>
          <div class="col-3 mr-0">
            <h3>quick links</h3>
            <ul class="text-left" style="list-style-type:none">
              <!--<li><router-link to="/requests/vacation">schedule vacation</router-link></li>-->
              <!--<li><router-link to="/requests/vacationAllowance">schedule vacation allowance</router-link></li>-->
              <!--<li><router-link to="/requests/fieldVacationScheduleOption">field schedule option</router-link></li>-->
              <!--<li><router-link to="/requests/personalBusinessDays">schedule personal business days</router-link></li>-->
              <li><router-link to="/requests/rnr">schedule r&amp;r request</router-link></li>
              <!--<li v-if="currentUser.isAdmin || currentUser.canRaiseMedicalRequests"><router-link to="/requests/sickLeave">schedule sick leave</router-link></li>-->
              <!--<li v-if="currentUser.isAdmin || currentUser.canRaiseMedicalRequests"><router-link to="/requests/maternityLeave">schedule maternity leave</router-link></li>-->
              <li><router-link to="/requests/view">my requests</router-link></li>
              <li><router-link to="/requests/pending">requests pending my action</router-link></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  // @ is an alias to /src
  import Welcome from '@/components/Welcome.vue'
  import GeneralMixin from '@/mixins/GeneralMixin'

  export default {
    name: 'home',
    mixins: [GeneralMixin],
    components: {
      Welcome
    },
    data: function () {
      return {
        msg: null
      }
    },
    computed: {
      ...mapState({
        currentUser: state => state.userModule.currentUser
      })
    },
    mounted() {
      const currentDate = new Date()
      const hours = currentDate.getHours()
      const timeOfDay = hours < 12 ? 'morning' : (hours < 16 ? 'afternoon' : 'evening')
      this.msg = `good ${timeOfDay}, ${this.currentUser.firstName}`
    }
  }
</script>
