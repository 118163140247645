import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

import { SET_LAYOUT } from '@/store/mutation-type'

import Home from '../views/Home.vue'
const FieldVacationScheduleOptionRequestView = () => import('@/views/fieldVacationOption')
const VacationCashInLieuRequestView = () => import('@/views/cashInLieuOfVacation')
const VacationCarryOverRequestView = () => import('@/views/vacationCarryOver')
const VacationBuyBackRequestView = () => import('@/views/buyBack')
const VacationDaysAdjustmentRequestView = () => import('@/views/vacationDaysAdjustment')
const ReviewCashInLieuRequestView = () => import('@/views/cashInLieuOfVacation/review')
const ViewCashInLieuRequestView = () => import('@/views/cashInLieuOfVacation/view')
const ViewChangeRequest = () => import('@/views/vacationDaysChange/view')
const ReviewChangeRequest = () => import('@/views/vacationDaysChange/review')
const VacationRequestView = () => import('@/views/vacation/index')
const ReviewVacationRequestView = () => import('@/views/vacation/review')
const VacationRequestDisplayView = () => import('@/views/vacation/view')
const VacationRequestRecallView = () => import('@/views/vacation/recall')
const HolidaysNotTakenView = () => import('@/views/holidaysNotTaken')
const PersonalBusinessDaysRequestView = () => import('@/views/personalBusinessDay')
const RnRRequestView = () => import('@/views/rnr')
const SickLeaveRequestView = () => import(/* webpackChunkName: "medicalRequestViews" */ '@/views/sickLeave')
const MaternityLeaveRequestView = () => import(/* webpackChunkName: "medicalRequestViews" */ '@/views/maternityLeave')
const PaternityLeaveRequestView = () => import(/* webpackChunkName: "medicalRequestViews" */ '@/views/paternityLeave')
const CompassionateLeaveRequestView = () => import(/* webpackChunkName: "otherRequestViews" */ '@/views/compassionateLeave')
const StudyLeaveRequestView = () => import(/* webpackChunkName: "otherRequestViews" */ '@/views/studyLeave')
const LeaveOfAbsenceRequestView = () => import(/* webpackChunkName: "otherRequestViews" */ '@/views/leaveOfAbsence')
const ExtendLOARequestsView = () => import(/* webpackChunkName: "otherRequestViews" */ '@/views/loaRequest/extend')
const CloseLOARequestsView = () => import(/* webpackChunkName: "otherRequestViews" */ '@/views/loaRequest/close')
const MyRequestsView = () => import(/* webpackChunkName: "leaveRequestViews" */ '@/views/leaveRequest/MyRequests')
const RequestsPendingMeView = () => import(/* webpackChunkName: "leaveRequestViews" */ '@/views/leaveRequest/PendingMe')
// const MaternityLeaveRequestView = () => import(/* webpackChunkName: "medicalRequestViews" */ '@/views/maternityLeaveRequestView')
const RoleView = () => import(/* webpackChunkName: "adminViews" */ '@/views/admin/role')
const UserView = () => import(/* webpackChunkName: "adminViews" */ '@/views/admin/user')
const PublicHolidayView = () => import(/* webpackChunkName: "adminViews" */ '@/views/admin/publicHoliday')
const VacationMasterGenerationView = () => import(/* webpackChunkName: "adminViews" */ '@/views/admin/vacationMaster/generate')
const VacationAllowanceRequestView = () => import(/* webpackChunkName: "otherRequestViews" */ '@/views/vacationAllowance')

const VacationAllowanceSearchReportView = () => import(/* webpackChunkName: "reportViews" */ '@/views/reports/vacationAllowance/search')
const CashInLieuOfVacationReportView = () => import(/* webpackChunkName: "reportViews" */ '@/views/reports/cashInLieuOfVacation/search')
const LeaveRequestReportView = () => import(/* webpackChunkName: "reportViews" */ '@/views/reports/leaveRequests/search')
const MedicalLeaveRequestReportView = () => import(/* webpackChunkName: "reportViews" */ '@/views/reports/leaveRequests/medicalLeaveSearch')
const MyLeaveRequestsReportView = () => import(/* webpackChunkName: "reportViews" */ '@/views/reports/leaveRequests/myLeaveRequests')
const VacationDataReportView = () => import(/* webpackChunkName: "reportViews" */ '@/views/reports/vacationData/search')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  //{
  //  path: '/requests/vacation',
  //  name: 'enterVacationRequest',
  //  component: VacationRequestView
  //},
  //{
  //  path: '/requests/vacation/edit/:id',
  //  name: 'editVacationRequest',
  //  component: VacationRequestView
  //},
  //{
  //  path: '/requests/vacation/review/:employeeId/:id',
  //  name: 'reviewVacationRequest',
  //  component: ReviewVacationRequestView
  //},
  //{
  //  path: '/requests/vacation/view/:id',
  //  name: 'viewVacationRequest',
  //  component: VacationRequestDisplayView
  //},
  //{
  //  path: '/requests/vacation/recall',
  //  name: 'enterVacationRecallRequest',
  //  component: VacationRequestRecallView
  //},
  //{
  //  path: '/requests/vacationAllowance',
  //  name: 'enterVacationAllowanceRequest',
  //  component: VacationAllowanceRequestView
  //},
  //{
  //  path: '/requests/vacation/holidaysNotTaken',
  //  name: 'enterHolidaysNotTakenRequest',
  //  component: HolidaysNotTakenView
  //},
  //{
  //  path: '/requests/cashinlieuOfVacation/:employeeId?/:vacationYear?',
  //  name: 'enterCashInLieuOfVacationRequest',
  //  component: VacationCashInLieuRequestView
  //},
  //{
  //  path: '/requests/cashinlieuOfVacation/review/:employeeId/:id',
  //  name: 'reviewCashInLieuOfVacationRequest',
  //  component: ReviewCashInLieuRequestView
  //},
  //{
  //  path: '/requests/cashinlieuOfVacation/view/:id',
  //  name: 'viewCashInLieuOfVacationRequest',
  //  component: ViewCashInLieuRequestView
  //},
  //{
  //  path: '/requests/vacationBuyBack/',
  //  name: 'enterVacasionBuyBack',
  //  component: VacationBuyBackRequestView,
  //  meta: { requiredRights: 'canAdministerBenefits' }
  //},
  //{
  //  path: '/requests/vacationCarryOver/',
  //  name: 'enterVacasionCarryOver',
  //  component: VacationCarryOverRequestView
  //},
  //{
  //  path: '/requests/vacationDaysAdjustment/',
  //  name: 'enterVacasionDaysAdjustment',
  //  component: VacationDaysAdjustmentRequestView,
  //  meta: { requiredRights: ['canAdministerBenefits', 'isExpatriateAdmin'] }
  //},
  //{
  //  path: '/requests/viewChangeRequest/view/:employeeId/:id',
  //  name: 'viewChangeRequest',
  //  component: ViewChangeRequest
  //},
  //{
  //  path: '/requests/reviewChangeRequest/review/:employeeId/:id',
  //  name: 'reviewChangeRequest',
  //  component: ReviewChangeRequest
  //},
  //{
  //  path: '/requests/fieldVacationScheduleOption',
  //  name: 'enterFieldVacationScheduleOption',
  //  component: FieldVacationScheduleOptionRequestView
  //},
  //{
  //  path: '/requests/personalBusinessDays',
  //  name: 'enterPersonalBusinessDaysRequest',
  //  component: PersonalBusinessDaysRequestView
  //},
  //{
  //  path: '/requests/personalBusinessDays/edit/:id',
  //  name: 'editPersonalBusinessDaysRequest',
  //  component: PersonalBusinessDaysRequestView
  //},
  //{
  //  path: '/requests/personalBusinessDays/view/:id',
  //  name: 'viewPersonalBusinessDaysRequest',
  //  component: PersonalBusinessDaysRequestView
  //},
  //{
  //  path: '/requests/personalBusinessDays/review/:id',
  //  name: 'reviewPersonalBusinessDaysRequest',
  //  component: PersonalBusinessDaysRequestView
  //},
  {
    path: '/requests/rnr',
    name: 'enterRnR',
    component: RnRRequestView
  },
  {
    path: '/requests/rnr/edit/:id',
    name: 'editRnRRequest',
    component: RnRRequestView
  },
  {
    path: '/requests/rnr/view/:id',
    name: 'viewRnRRequest',
    component: RnRRequestView
  },
  {
    path: '/requests/rnr/review/:id',
    name: 'reviewRnRRequest',
    component: RnRRequestView
  },
  //{
  //  path: '/requests/sickLeave',
  //  name: 'enterSickLeaveRequest',
  //  component: SickLeaveRequestView,
  //  meta: { requiredRights: 'canRaiseMedicalRequests' }
  //},
  //{
  //  path: '/requests/sickLeave/edit/:id',
  //  name: 'editSickLeaveRequest',
  //  component: SickLeaveRequestView,
  //  meta: { requiredRights: 'canRaiseMedicalRequests' }
  //},
  //{
  //  path: '/requests/sickLeave/view/:id',
  //  name: 'viewSickLeaveRequest',
  //  component: SickLeaveRequestView
  //},
  //{
  //  path: '/requests/sickLeave/review/:id',
  //  name: 'reviewSickLeaveRequest',
  //  component: SickLeaveRequestView
  //},
  //{
  //  path: '/requests/maternityLeave',
  //  name: 'enterMaternityLeaveRequest',
  //  component: MaternityLeaveRequestView,
  //  meta: { requiredRights: 'canRaiseMedicalRequests' }
  //},
  //{
  //  path: '/requests/maternityLeave/edit/:id',
  //  name: 'editMaternityLeaveRequest',
  //  component: MaternityLeaveRequestView,
  //  meta: { requiredRights: 'canRaiseMedicalRequests' }
  //},
  //{
  //  path: '/requests/maternityLeave/view/:id',
  //  name: 'viewMaternityLeaveRequest',
  //  component: MaternityLeaveRequestView
  //},
  //{
  //  path: '/requests/maternityLeave/review/:id',
  //  name: 'reviewMaternityLeaveRequest',
  //  component: MaternityLeaveRequestView
  //},
  //{
  //  path: '/requests/paternityLeave',
  //  name: 'enterPaternityLeaveRequest',
  //  component: PaternityLeaveRequestView
  //},
  //{
  //  path: '/requests/paternityLeave/edit/:id',
  //  name: 'editPaternityLeaveRequest',
  //  component: PaternityLeaveRequestView
  //},
  //{
  //  path: '/requests/paternityLeave/view/:id',
  //  name: 'viewPaternityLeaveRequest',
  //  component: PaternityLeaveRequestView
  //},
  //{
  //  path: '/requests/paternityLeave/review/:id',
  //  name: 'reviewPaternityLeaveRequest',
  //  component: PaternityLeaveRequestView
  //},
  //{
  //  path: '/requests/compassionateLeave',
  //  name: 'enterCompassionateLeaveRequest',
  //  component: CompassionateLeaveRequestView
  //},
  //{
  //  path: '/requests/compassionateLeave/edit/:id',
  //  name: 'editCompassionateLeaveRequest',
  //  component: CompassionateLeaveRequestView
  //},
  //{
  //  path: '/requests/compassionateLeave/view/:id',
  //  name: 'viewCompassionateLeaveRequest',
  //  component: CompassionateLeaveRequestView
  //},
  //{
  //  path: '/requests/compassionateLeave/review/:id',
  //  name: 'reviewCompassionateLeaveRequest',
  //  component: CompassionateLeaveRequestView
  //},
  //{
  //  path: '/requests/studyLeave',
  //  name: 'enterStudyLeaveRequest',
  //  component: StudyLeaveRequestView
  //},
  //{
  //  path: '/requests/studyLeave/edit/:id',
  //  name: 'editStudyLeaveRequest',
  //  component: StudyLeaveRequestView
  //},
  //{
  //  path: '/requests/studyLeave/view/:id',
  //  name: 'viewStudyLeaveRequest',
  //  component: StudyLeaveRequestView
  //},
  //{
  //  path: '/requests/studyLeave/review/:id',
  //  name: 'reviewStudyLeaveRequest',
  //  component: StudyLeaveRequestView
  //},
  //{
  //  path: '/requests/leaveOfAbsence/edit/:id',
  //  name: 'editLeaveOfAbsenceRequest',
  //  component: LeaveOfAbsenceRequestView
  //},
  //{
  //  path: '/requests/leaveOfAbsence/view/:id',
  //  name: 'viewLeaveOfAbsenceRequest',
  //  component: LeaveOfAbsenceRequestView
  //},
  //{
  //  path: '/requests/leaveOfAbsence',
  //  name: 'enterLeaveOfAbsenceRequest',
  //  component: LeaveOfAbsenceRequestView
  //},
  //{
  //  path: '/requests/leaveOfAbsence/review/:id',
  //  name: 'reviewLeaveOfAbsenceRequest',
  //  component: LeaveOfAbsenceRequestView
  //},
  //{
  //  path: '/requests/loa/extend',
  //  name: 'extendLOARequests',
  //  component: ExtendLOARequestsView,
  //  meta: { requiredRights: 'canAdministerBenefits' }
  //},
  //{
  //  path: '/requests/loa/close',
  //  name: 'closeLOARequests',
  //  component: CloseLOARequestsView,
  //  meta: { requiredRights: 'canAdministerBenefits' }
  //},
  {
    path: '/requests/view',
    name: 'myRequests',
    component: MyRequestsView
  },
  {
    path: '/requests/pending',
    name: 'requestsPendingMe',
    component: RequestsPendingMeView
  },
  {
    path: '/admin/users',
    name: 'users',
    component: UserView,
    meta: { requiredRights: 'canManageUsers' }
  },
  {
    path: '/admin/roles',
    name: 'roles',
    component: RoleView,
    meta: { requiredRights: 'canManageRoles' }
  },
  {
    path: '/admin/publicHolidays',
    name: 'publicHolidays',
    component: PublicHolidayView,
    meta: { requiredRights: 'canManagePublicHolidays' }
  },
  {
    path: '/admin/vacationmaster/generate',
    name: 'VacationMasterGenerationView',
    component: VacationMasterGenerationView,
    meta: { requiredRights: 'isExpatriateAdmin' }
  },
  {
    path: '/reports/vacationAllowance/search',
    name: 'VacationAllowanceSearchReportView',
    component: VacationAllowanceSearchReportView,
    meta: { requiredRights: 'canAdministerBenefits' }
  },
  {
    path: '/reports/cashInLieuOfVacation/search',
    name: 'CashInLieuOfVacationReportView',
    component: CashInLieuOfVacationReportView,
    meta: { requiredRights: 'canAdministerBenefits' }
  },
  {
    path: '/reports/leaveRequest/search',
    name: 'LeaveRequestReportView',
    component: LeaveRequestReportView,
    meta: { requiredRights: 'canAdministerBenefits' }
  },
  {
    path: '/reports/medicalLeaveRequest/search',
    name: 'MedicalLeaveRequestReportView',
    component: MedicalLeaveRequestReportView,
    meta: { requiredRights: 'canRunMedicalReports' }
  },
  {
    path: '/reports/myLeaveRequests/search',
    name: 'MyLeaveRequestsReportView',
    component: MyLeaveRequestsReportView
  },
  {
    path: '/reports/vacationdata/search',
    name: 'VacationDataReportView',
    component: VacationDataReportView,
    meta: { requiredRights: 'canAdministerBenefits' }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const setLayout = function (to) {
  let layout = 'layout12'
  if (to.matched.some(record => record.meta.layout)) {
    layout = to.matched[0].meta.layout
  }
  if (layout !== store.state.layout.currentLayout) {
    store.commit(SET_LAYOUT, layout)
  }
}

router.beforeEach((to, from, next) => {
  setLayout(to)
  if (to.matched.some(record => record.meta.requiredRights)) {
    if (Array.isArray(to.matched[0].meta.requiredRights)) {
      for (const right of to.matched[0].meta.requiredRights) {
        if (store.state.userModule.currentUser.isAdmin || store.state.userModule.currentUser[right]) {
          next()
          break
        }
      }
    }
    else if (store.state.userModule.currentUser.isAdmin || store.state.userModule.currentUser[to.matched[0].meta.requiredRights]) {
      next()
    }
    else {
      next({
        path: '/'
      })
    }
  }
  else {
    next()
  }
})

export default router
